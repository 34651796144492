import { SortTypes } from ":data/types";
import moment from "moment";
import {
  FilterParams,
  FilterPayload,
  MediaGetPagedDto,
  TeamGetDto,
} from "./api.dto";
import api from "./api.instance";

const DateFormat = "YYYY-MM-DDTHH:mm:ssZ";

const getMedia = async ({
  page,
  size,
  filters,
  include,
}: {
  page: number;
  size: number;
  filters: FilterPayload;
  include: string;
}): Promise<MediaGetPagedDto> => {
  const {
    dayOnly,
    sort,
    dates,
    tags,
    types,
    hasGps,
    userIds,
    publishedOnly,
    finishedOnly,
    siteIds,
    userEmail,
  } = filters;

  const params: FilterParams = {
    sort: sort ? sort : SortTypes.DateNewestFirst,
    dayOnly: dayOnly,
    dateString:
      dates && dates.from ? moment(dates.from).format(DateFormat) : undefined,
    dateStringEnd:
      dates && dates.to ? moment(dates.to).format(DateFormat) : undefined,
    tags: tags && tags.length > 0 ? tags.join(",") : undefined,
    types: types ? types.join(",") : undefined,
    hasGpsOnly: hasGps,
    publishedOnly: publishedOnly,
    finishedOnly: finishedOnly,
    siteIds: siteIds ? siteIds.join(",") : undefined,
    userIds: userIds ? userIds.join(",") : undefined,
    userEmail: userEmail,
  };

  const route = `media/all`;

  const r = (
    await api.get(route, {
      params: { page, size, include, ...params },
    })
  ).data;
  return r as MediaGetPagedDto;
};

const getTeams = async (): Promise<TeamGetDto[]> => {
  const r = (await api.get("team/all")).data;
  return r as TeamGetDto[];
};

const ApiService = {
  getMedia,
  getTeams,
};

export default ApiService;
