import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Chip, { ChipProps } from "@mui/material/Chip";
import { BvTextCrop } from "./BvTexts";

interface Props extends Omit<ChipProps, "label" | "children" | "component"> {
  children: string;
  bold?: boolean;
  classes?: {
    container?: string;
    label?: string;
  };
}

const BvTag = ({ children, bold = false, classes, ...rest }: Props) => {
  const cl = useStyles();
  return (
    <Chip
      className={[bold ? cl.bold : "", classes?.container ?? ""].join(" ")}
      {...rest}
      label={children}
      component={(props) => <BvTextCrop {...props}>{children}</BvTextCrop>}
    />
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    tag: {
      justifyContent: "flex-start",
      padding: 4,
      margin: 4,
      borderRadius: 4,
      backgroundColor: "unset",
      border: "1px solid #000000 12",
    },
    bold: {
      fontWeight: "bold",
      backgroundColor: "#4776E6",
      color: "black",
    },
  })
);

export default BvTag;
