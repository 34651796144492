import { setAuthToken } from ":api/api.instance";
import ApiService from ":api/api.service";
import Gallery from ":components/views/Gallery";
import { useAppDispatch } from ":data/hooks";
import {
  fetchMedia,
  useErrorSelector,
  useLoadingSelector,
} from ":data/media/media.slice";
// import { fetchTeams } from ":data/team/team.slice";
import { FilterStateData, MediaType, SortTypes } from ":data/types";
import React, { useCallback, useEffect, useState } from "react";
import "./App.css";

declare global {
  interface Window {
    Logger: any;
  }
}

const URLS = {
  TOKEN: "tkn",
  SORT: "st",
  TAGS: "t",
  MEDIA: "m",
  SEARCH: "sr",
  UPLOADERS: "u",
  DATE_FROM: "ds",
  DATE_TO: "de",
  USER_EMAIL: "ue",
  TYPE: "tp",
  LAYOUT: "l",
};

function App() {
  // const [filters, setFilters] = useState<FilterStateData>({});
  const dispatch = useAppDispatch();
  const loading = useLoadingSelector();
  const [tknError, setTknError] = useState<string>("");
  const error = useErrorSelector();
  const sanitise = (s?: string): string =>
    (s ?? "").toLowerCase().replaceAll(/ -/g, "");

  const startFetch = useCallback(
    async (f: FilterStateData, shorthand?: string) => {
      try {
        let siteIds: string | undefined = undefined;
        if (shorthand) {
          const sites = await ApiService.getTeams();
          const site = sites.find(
            (s) => sanitise(s.shorthand) === sanitise(shorthand)
          );
          if (site) siteIds = site.teamId;
        }
        console.log("Dispatching stuff!");

        // dispatch(fetchTeams(0));
        dispatch(fetchMedia({ ...f, site: siteIds }));
      } catch (error) {
        setTknError("Something went wrong");
      }
    },
    [dispatch]
  );

  /** If we have search params, set the filters */
  useEffect(() => {
    const s = new URLSearchParams(window.location.href.replace(/^.*\?/, ""));
    if (s.has(URLS.TOKEN)) {
      console.log("S has URL token");
      setAuthToken(s.get(URLS.TOKEN)).then(() => {
        const shorthand = window.location.pathname.replace(/^\//, "");
        console.log("Here I am!");
        console.log(s.toString());
        let f: FilterStateData = {};
        if (s.has(URLS.SORT))
          f.sort = (s.get(URLS.SORT) as SortTypes) ?? undefined;
        if (s.has(URLS.DATE_FROM) || s.has(URLS.DATE_TO)) f.date = {};
        if (s.has(URLS.DATE_FROM))
          f.date!.from = new Date(s.get(URLS.DATE_FROM) ?? "");
        if (s.has(URLS.DATE_TO))
          f.date!.to = new Date(s.get(URLS.DATE_TO) ?? "");
        if (s.has(URLS.MEDIA))
          f.mediaType = (s.get(URLS.MEDIA) as MediaType) ?? undefined;
        if (s.has(URLS.SEARCH)) f.search = s.get(URLS.SEARCH) ?? undefined;
        if (s.has(URLS.USER_EMAIL))
          f.userEmail = s.get(URLS.USER_EMAIL) ?? undefined;
        if (s.has(URLS.TYPE))
          f.mediaType = (s.get(URLS.TYPE) as MediaType) ?? null;
        if (s.has(URLS.TAGS))
          f.tags = s.get(URLS.TAGS)?.split(",") ?? undefined;
        if (s.has(URLS.UPLOADERS))
          f.uploaders = s.get(URLS.UPLOADERS)?.split(",") ?? undefined;
        // if (s.has(URLS.LAYOUT))
        //   setLayout(Number.parseInt(s.get(URLS.LAYOUT) ?? "0") as Layout);

        startFetch(f, shorthand);
      });
    } else {
      setTknError("No token provided");
    }
  }, [startFetch]);

  console.log({ error });

  return (
    <div className="App">
      {tknError ? (
        tknError
      ) : loading ? (
        <span>Loading...</span>
      ) : error ? (
        <span>
          Error:{" "}
          {typeof error === "string"
            ? error
            : error?.message ?? "Something went wrong!"}
        </span>
      ) : (
        <Gallery />
      )}
    </div>
  );
}

export default App;
