import { configureStore } from "@reduxjs/toolkit";
import mediaReducer from "./media/media.slice";
import teamReducer from "./team/team.slice";

const store = configureStore({
  devTools: true,
  reducer: {
    media: mediaReducer,
    team: teamReducer
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
