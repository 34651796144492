import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";

/** For now, this component remains a JS file, as I don't know if the amp functions will work in TS */
class AMPlayer extends Component {
  static propTypes = {
    ampLoadTimeout: PropTypes.number,
    src: PropTypes.string.isRequired,
    mainRef: PropTypes.object,
    sources: PropTypes.array,
    onError: PropTypes.func.isRequired,
    fov: PropTypes.number,
    start: PropTypes.object,
    passRef: PropTypes.func,
    is360: PropTypes.bool,
    setReady: PropTypes.func,
    preview: PropTypes.string,
    audioEnabled: PropTypes.bool,
  };

  static defaultProps = {
    ampLoadTimeout: 300,
    fov: 1,
    start: {
      time: null,
      pitch: null,
      yaw: null,
      hfov: null,
    },
    // is360: true,
    // audioEnabled: false,
    setReady: (b) => console.log(b, "AMPlayer"),
  };

  shouldComponentUpdate(nextProps) {
    // console.trace(
    //   { old: this.props, new: nextProps },
    //   "AMPlayer :: shouldComponentUpdate"
    // );
    return (
      this.props.src !== nextProps.src ||
      this.props.fov !== nextProps.fov ||
      this.props.is360 !== nextProps.is360 ||
      this.props.audioEnabled !== nextProps.audioEnabled
    );
  }

  buildComponent() {
    console.info(this.props.start.time, "Building AMPlayer");
    this.waitForAmp()
      .then((amp) => {
        console.debug(amp, "AMPlayer :: componentDidMount // AMP loaded");
        this.amp = amp;
        this.videoPlayer = this.createVideoPlayer(
          amp,
          this.props.is360,
          this.props.audioEnabled
        );
        if (this.props.passRef) {
          this.props.passRef(this.videoPlayer);
          console.debug("Passing ref", "AMPlayer :: componentDidMount // src");
        }
        console.debug(this.props.src, "AMPlayer :: componentDidMount // src");

        this.videoPlayer.src([
          {
            src: this.props.src,
            type: this.isMp4() ? "video/mp4" : "application/vnd.ms-sstr+xml",
          },
        ]);
        this.videoPlayer.on("loadeddata", (e) => {
          this.props.setReady(true);
          if (this.props.passRef && !this.props.mainRef)
            this.props.passRef(this.videoPlayer);

          console.log(e, "AMPlayer :: Loaded data!");
        });
        setTimeout(() => {
          try {
            if (this.props.start.time !== null && this.props.start.time > 1) {
              this.videoPlayer.currentTime(this.props.start.time);
              this.videoPlayer.pause();
              console.debug(
                `Seeked to ${this.props.start.time}`,
                "AMPlayer :: setTimeout"
              );
            }
          } catch (err) {
            // TODO replace with specific error code in known cases
        console.warn(err, "AMPlayer :: setTimeout");
          }
        }, 1000);
      })
      .catch(
        (err) => {
          // TODO replace with specific error code in known cases
          console.warn(err, "AMPlayer :: waitForAmp");
        }
        // console.error("Could not found Azure Media Player plugin", e)
      );
  }

  componentDidMount() {
    // Event listener for whenever the window resizes TODO fix?
    // $(window).on("resize", () => {
    //   // We essentially say the height of the video player is either constrained by
    //   // the space in the window left (heightWindowConstrained) or if the width is
    //   // 100%, we are then constrained by the aspect ratio (heightWidthConstrained)
    //   let heightWindowConstrained =
    //     $(window).height() -
    //     $("#topbar").outerHeight() -
    //     $(".bv-video-undercard").outerHeight() -
    //     10;
    //   let heightWidthConstrained =
    //     ($(window).width() -
    //       $("#sidebar").outerWidth() -
    //       $("#tabs-sidebar").outerWidth() -
    //       100) *
    //     0.5625;

    //   // Is it constained by the window or the width?
    //   let isWindowConstrained =
    //     heightWindowConstrained < heightWidthConstrained ? true : false;

    //   if (Math.min(heightWindowConstrained, heightWidthConstrained) < 300) {
    //     //It becomes too small so we swap back to simple width fill, with potential vertical cutoff
    //     $("#container").css({
    //       width: "100%",
    //       height: 0,
    //       "padding-top": "56.25%",
    //     });
    //   } else {
    //     // Set the css to what its constained by and calculate the width based off that
    //     $("#container").css({
    //       height: `${Math.min(
    //         heightWindowConstrained,
    //         heightWidthConstrained
    //       )}px`,
    //       width: `${
    //         isWindowConstrained ? 1.77 * heightWindowConstrained + "px" : "100%"
    //       }`,
    //       "padding-top": 0,
    //     });
    //   }
    // });

    this.buildComponent();
  }

  componentWillUnmount() {
    console.info("AMPlayer unmounting");
    $(window).off("resize");
    try {
      if (this.videoPlayer) {
        this.videoPlayer.volume(0);
        this.videoPlayer.dispose();
        console.log("Disposed!", "AMPlayer :: componentWillUnmount");
      }
    } catch (e) {
      console.warn(e, "AMPlayer : componentWillUnmount");
    }
  }

  // Why was this here?
  componentDidUpdate() {
    try {
      console.info(this.videoPlayer, "cDU videoPlayer");
      // this.buildComponent();
      if (this.videoPlayer) {
        this.videoPlayer.src([
          {
            src: this.props.src,
            type: this.isMp4() ? "video/mp4" : "application/vnd.ms-sstr+xml",
          },
        ]);
      }
    } catch (e) {
      console.critical(e, "AMPlayer :: componentDidUpdate");
    }
  }

  isMp4() {
    if (!this.props.src) return false;
    return this.props.src.match(/\.mp4/);
  }

  createVideoPlayer = (amp, is360, audioEnabled) => {
    const video = amp(
      this.videoRef,
      {
        // nativeControlsForTouch: false,
        autoplay: true, // DO NOT CHANGE
        controls: true,
        logo: { enabled: false },
        // fluid: true,
        height: "100%",
        width: "100%",
        muted: audioEnabled !== true,
        inactivityTimeout: 0,
        nativeControlsForTouch: false,
        preview: this.props.preview,
        allowfullscreen: true,
        webkitallowfullscreen: true,
        mozallowfullscreen: true,

        playbackSpeed: {
          enabled: true,
          initialSpeed: 1.0,
          speedLevels: [
            // { name: "x4.0", value: 4.0 },
            // { name: "x3.0", value: 3.0 },
            { name: "x2.0", value: 2.0 },
            // { name: "x1.75", value: 1.75 },
            { name: "x1.5", value: 1.5 },
            // { name: "x1.25", value: 1.25 },
            { name: "normal", value: 1.0 },
            { name: "x0.75", value: 0.75 },
            { name: "x0.5", value: 0.5 },
          ],
        },
        heuristicProfile: "QuickStart",
        techOrder: this.isMp4()
          ? ["html5", "azureHtml5JS", "html5FairPlayHLS"]
          : ["azureHtml5JS", "html5FairPlayHLS", "html5"],
        plugins: is360
          ? {
              pannellum: {
                hfov: this.props.start.hfov ? this.props.start.hfov : 150,
                pitch: this.props.start.pitch ? this.props.start.pitch : 0,
                yaw: this.props.start.yaw ? this.props.start.yaw : 0,
              },
              // threeSixty: { fov: this.props.fov },
            }
          : {},
      },
      () => {
        this.videoRef.visibility = "visible";
        console.info("Video ready!", "AMPlayer :: createVideoPlayer :: onReady");
        video.play(); // seek pause?
        // video.muted = audioEnabled;
        if (audioEnabled !== true) {
          const I = setInterval(() => {
            try {
              $(".vjs-volume-control").hide();
              console.debug(
                "Disabled audio",
                "AMPlayer :: createVideoPlayer :: onReady"
              );
            } catch (e) {
              console.warn("Unable to disable audio");
            }
          }, 200);
          setTimeout(() => clearInterval(I), 2000);
        }
      }
    );
    video.addEventListener(amp.eventName.error, (errorDetails) => {
      console.warn(errorDetails, "AMPlayer :: videoErrorListener");
      this.props.onError();
    });

    return video;
  };

  waitForAmp = () => {
    return new Promise((resolve, reject) => {
      let waited = 0;
      const wait = (interval) => {
        setTimeout(() => {
          waited += interval;
          const amp = window["amp"];
          if (amp !== undefined) {
            return resolve(amp);
          }
          if (waited >= this.props.ampLoadTimeout * 100) {
            return reject();
          }
          wait(interval * 2);
          return null;
        }, interval);
      };
      wait(30);
    });
  };

  render() {
    return (
      <div className="amplayer-container" id="container">
        <video
          className="azuremediaplayer amp-default-skin amp-big-play-centered"
          ref={(input) => {
            this.videoRef = input;
          }}
          autoPlay
          muted={this.props.audioEnabled !== true}
          tabIndex="0"
          crossOrigin="anonymous"
          // visibility="hidden"
          preview={this.props.preview}
        ></video>
      </div>
    );
  }
}

export default AMPlayer;
