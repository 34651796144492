export enum Layout {
  Gallery,
  List,
  Map,
}
export enum SortTypes {
  DateNewestFirst = "DateNewestFirst",
  DateOldestFirst = "DateOldestFirst",
  UploaderAZ = "UploaderAZ",
  UploaderZA = "UploaderZA",
  Unknown = "",
}

export enum MediaType {
  Video = "Video",
  Photo = "Photo",
  Is360 = "Is360",
  Unknown = "",
}

/** A date range with an optional start and end */
export type DateRange = {
  from?: Date;
  to?: Date;
};

export type User = {
  id: string;
  name: string;
};

export type FilterState = {
  tags: string[];
  search: string;
  /** Which site is currently selected, or "" for "mine" */
  site: "" | string | "all";
  date: DateRange;
  uploaders: User["id"][];
  mediaType: MediaType | null;
  sort: SortTypes;
  hasGps?: boolean;
  publishedOnly?: boolean;
  finishedOnly?: boolean;
  userEmail?: string;
};
export type FilterStateData = Partial<FilterState>;
