import axios from "axios";

// const setBaseUrl = (url: string) => {
//   api.defaults.baseURL = url + "api/";
// };
// If we have a user token, always include it in requests
export const setAuthToken = async (token: string | null) => {
  console.log("Setting auth token with " + token);
  if (token && token !== "undefined") {
    api.defaults.headers.common["x-api-key"] = `${token}`;
    localStorage.setItem("token", token);
  } else {
    delete api.defaults.headers.common["x-api-key"];
    localStorage.removeItem("token");
  }
};

const api = axios.create({
  baseURL:
    window.location.host === "embed.builtview.com"
      ? "https://api.builtview.com/api/public"
      : window.location.host === "staging.embed.builtview.com"
      ? "https://api-staging.builtview.com/api/public"
      : "https://app-bv-server-minefield-australiaeast.azurewebsites.net/api/public",
  // baseURL: "https://builtview360backend-minefield.azurewebsites.net/api/public",
});

export const getFrontendLink = (mediaId: string, siteId?: string) => {
  var baseUrl = "";
  if (window.location.host === "embed.builtview.com")
    baseUrl = "https://app.builtview.com";
  else if (window.location.host === "staging.embed.builtview.com")
    baseUrl = "https://staging.builtview.com";
  else baseUrl = "https://preview.builtview.com";
  if (siteId) return `${baseUrl}/gallery/${siteId}/tile/view/${mediaId}`;
  else return `${baseUrl}/gallery/view/${mediaId}`;
};

export default api;
