/** Wrapper components for text elements,
 *  to simplify importing and customisation */

import Typography, { TypographyProps } from "@mui/material/Typography";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";

interface BaseProps extends TypographyProps {}

/** Simple text wrapper - fully configurable */
export const BvText = (props: BaseProps) => {
  return <Typography {...props} />;
};

type ParagraphProps = Omit<BaseProps, "paragraph">;

/** Convenience wrapper for paragraph text */
export const BvParagraph = (props: ParagraphProps) => {
  return <Typography {...props} paragraph />;
};

type Size = "sm" | "md" | "lg";

interface HeaderProps extends Omit<BaseProps, "variant"> {
  size: Size;
}

/** Wrapper for header elements, used to standardise the sizes of headers we use */
export const BvHeader = ({ size, ...rest }: HeaderProps) => {
  const sizeToVariant = (s: Size): BaseProps["variant"] => {
    switch (s) {
      case "sm":
        return "h5";
      case "md":
        return "h3";
      case "lg":
        return "h1";
    }
  };

  return <Typography {...rest} variant={sizeToVariant(size)} />;
};

interface CropProps extends Omit<BaseProps, "noWrap" | "children"> {
  children: TooltipProps["title"];
  overwriteTooltip?: string;
  forceTooltip?: boolean;
}

/** Wrapper for text which should cut off and have a tooltip if too long */
export const BvTextCrop = ({
  children,
  overwriteTooltip,
  forceTooltip = false,
  ...rest
}: CropProps) => {
  // https://stackoverflow.com/questions/56588625/react-show-material-ui-tooltip-only-for-text-that-has-ellipsis
  const ref = useRef<any>(null);
  const [hover, setHover] = useState<boolean>(false);

  const compareSize = () => {
    if (!ref.current) return;
    const compare = ref.current!.scrollWidth > ref.current!.clientWidth;
    setHover(compare);
  };

  /** Compare once and add resize listener */
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => window.removeEventListener("resize", compareSize);
  });

  return (
    <Tooltip
      title={overwriteTooltip ?? children}
      disableHoverListener={!hover && !forceTooltip}
    >
      <Typography {...rest} ref={ref} noWrap align={rest.align}>
        {children}
      </Typography>
    </Tooltip>
  );
};
