import { useAppSelector } from ":data/hooks";
import { FilterStateData } from ":data/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { FilterParams } from ":api/api.dto";
import { generateFilterPayload } from ":api/api.helpers";
import ApiService from ":api/api.service";
import { Media, MediaState } from "./media.types";

const initialState: MediaState = {
  items: [],
  error: null,
  loading: true,
};

export const fetchMedia = createAsyncThunk(
  "media/fetchAll",
  async (filters: FilterStateData, thunkAPI) => {
    try {
      console.log({ filters, payload: generateFilterPayload(filters) });
      const response = await ApiService.getMedia({
        page: 0,
        size: 100,
        filters: generateFilterPayload(filters),
        include: "Thumb,Link",
      });
      return response.results;
    } catch (error: any) {
      if (error?.response?.data)
        throw thunkAPI.rejectWithValue(error.response.data);
      else throw error;
    }
  }
);

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    getMedias: (state, action: PayloadAction<Media[]>) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMedia.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchMedia.rejected, (state, action) => {
      console.log("Error");
      console.log(action);
      state.loading = false;
      state.error =
        (action.payload as string) ??
        action.error?.message ??
        "Something went wrong";
    });
  },
});

const mediaReducer = mediaSlice.reducer;
export default mediaReducer;

export function useLoadingSelector() {
  return useAppSelector((state) => state.media.loading);
}

export function useMediasSelector() {
  return useAppSelector((state) => state.media.items ?? []);
}
export function useMediaSelector(id: Media["id"]) {
  return useAppSelector((state) => state.media.items.find((i) => i.id === id));
}

export function useErrorSelector() {
  return useAppSelector((state) => state.media.error);
}
export const { getMedias } = mediaSlice.actions;
