import { FilterStateData } from ":data/types";
import { FilterPayload } from "./api.dto";

export const generateFilterPayload = (
  filters: FilterStateData
): FilterPayload => {
  return {
    dayOnly: true,
    tags: filters.tags,
    dates: filters.date,
    sort: filters.sort,
    hasGps: filters.hasGps,
    publishedOnly: false,
    finishedOnly: false,
    types: filters.mediaType ? [filters.mediaType] : undefined,
    siteIds:
      filters.site && filters.site !== "all" ? [filters.site] : undefined,
    userIds: filters.uploaders ? filters.uploaders : undefined,
    userEmail: filters.userEmail ? filters.userEmail : undefined,
  };
};
