import React, { Fragment, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
// import { useAppSelector } from ":data/hooks";
import { useMediasSelector } from ":data/media/media.slice";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Box from "@mui/material/Box";
import { Media } from ":data/media/media.types";
import PhotoModal from ":components/layout/PhotoModal";
import { BvHeader } from ":components/BvTexts";

interface Props {}

const Gallery = (props: Props) => {
  const items = useMediasSelector();
  const [selected, setSelected] = useState<Media | undefined>();
  const classes = useStyles();
  return items.length === 0 ? (
    <BvHeader size="md">No items found</BvHeader>
  ) : (
    <Fragment>
      <Box sx={{ overflowY: "scroll" }}>
        <ImageList
          variant="masonry"
          cols={4}
          gap={8}
          className={classes.itemlist}
        >
          {items.map((item) => (
            <ImageListItem key={item.id} className={classes.item}>
              <img
                onClick={() => setSelected(item)}
                src={`${item.thumbnail}`}
                srcSet={`${item.thumbnail}`}
                alt={item.description ?? "A capture from BuiltView"}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <PhotoModal
        isOpen={!!selected}
        onClose={() => setSelected(undefined)}
        item={selected}
      ></PhotoModal>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    itemlist: { padding: 4 },
    item: {
      cursor: "pointer",
      "&:hover": {
        border: "1px solid black",
        margin: -2,
      },
    },
  })
);

export default Gallery;
