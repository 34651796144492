import ApiService from ":api/api.service";
import { useAppSelector } from ":data/hooks";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Team, TeamState } from "./team.types";

const initialState: TeamState = {
  teams: [],
  loading: true,
};

export const fetchTeams = createAsyncThunk(
  "team/fetch",
  async (arg: unknown, thunkAPI) => {
    const response = await ApiService.getTeams();
    return response;
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    getTeams: (state, action: PayloadAction<Team[]>) => {
      state.teams = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      state.teams = action.payload.map((t) => ({
        id: t.teamId,
        name: t.teamName,
        latestDate: t.latestDate,
        mediaUploaders: t.mediaUploaders,
        tags: t.tags,
        teamTags: t.teamTags,
        users: t.users,
      }));
      state.loading = false;
    });
    builder.addCase(fetchTeams.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

const teamReducer = teamSlice.reducer;
export default teamReducer;

export function useTeamsSelector() {
  return useAppSelector((state) => state.team.teams);
}
export function useTeamSelector(id: Team["id"]) {
  return useAppSelector((state) => state.team.teams.find((i) => i.id === id));
}

export const { getTeams } = teamSlice.actions;
