import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import { Media } from ":data/media/media.types";
import BvTag from ":components/BvTag";
import ProgressiveImage from "react-progressive-graceful-image";
import AMPlayer from ":components/AMPlayer";
import { getFrontendLink } from ":api/api.instance";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  item?: Media;
}

const PhotoModal = ({ isOpen, onClose, item }: Props) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth={false}>
      <div className={classes.photomodal}>
        {item ? (
          <div className={classes.inner}>
            {item.type === "Video" && item.link ? (
              <AMPlayer
                className={classes.img}
                src={item.link}
                poster={item.thumbnail}
                onError={window.alert}
              />
            ) : (
              <ProgressiveImage
                src={item.link ?? item.thumbnail ?? ""}
                placeholder={item.thumbnail ?? ""}
              >
                {(src: string) => (
                  <img
                    className={classes.img}
                    src={src}
                    alt={item.description ?? "A capture from BuiltView"}
                  />
                )}
              </ProgressiveImage>
            )}
            <div className={classes.details}>
              <a
                target="_blank"
                rel="noreferrer"
                href={getFrontendLink(item.id, (item.siteIds ?? [])[0])}
              >
                View on BuiltView
              </a>
              <p>{item.description}</p>
              <div>
                {item.tags?.map((tag) => (
                  <BvTag>{tag}</BvTag>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    photomodal: {
      overflow: "hidden",
      maxHeight: "100%",
      height: "100vh",
      position: "relative",
    },
    inner: {
      display: "flex",
      // flexWrap: "wrap",
      flexDirection: "column",
      position: "relative",
      height: "100%",
      width: "100%",
      maxHeight: "100%",
      overflow: "hidden",
    },
    img: {
      maxHeight: "90%",
      maxWidth: "100%",
      objectFit: "contain",
      // flex: 2,
    },
    details: {
      // flex: 1,
      padding: 16,
      paddingTop: 0,
    },
  })
);

export default PhotoModal;
